<template>
<div class="admin-container">
    <div class="admin-container-header">
        <div class="flex justify-between">
            <h1>  Пользователи </h1>
            <div class="flex items-center">
                <el-input placeholder="Type something" suffix-icon="el-icon-search" v-model="input2"> </el-input> 
            </div>
        </div>
    </div>
    <div class="admin-table">
        <el-table :data="tableData" style="width: 100%;" default-expand-all>
            <el-table-column prop="email" label="Email" class="table-pl-20px">
            </el-table-column>
            <el-table-column prop="kode" label="1C Kode" width="180">
            </el-table-column>
            <el-table-column label="Дії" width="180" class="table-pr-20px ">
                <template slot-scope="scope">
                    <div class="flex">
                        <button @click="scope.row.block=false" v-if="scope.row.block" >Blokirovat</button>  
                        <button @click="scope.row.block=true" v-else >unBlokirovat</button>  
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="admin-poginations">
        <el-pagination  :page-sizes="[10, 20, 50, 100]" :page-size="10" @current-change="paginateSize" @size-change="paginateSize" :current-page.sync="current_page" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="5">
        </el-pagination>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            input2: '',
            current_page:1,
            tableData: [{
                    email: 'Admin@admin.com',
                    kode: '11450404',
                    block:false
                },   
                {
                    email: 'Admin@admin.com',
                    kode: '11450404',
                    block:false
                },   
                {
                    email: 'Admin@admin.com',
                    kode: '11450404',
                    block:false
                },   
                {
                    email: 'Admin@admin.com',
                    kode: '11450404',
                    block:false
                },   
            ]
        }

    },
    methods:{
        paginateSize(){
            console.log("AAA")
        }
    }
}
</script>

<style>
.admin-container {
    background: var(--white-bg);
    min-height: 100%;
    width: 100%;

}

.admin-container-header {
    padding: 20px;
}

.admin-table {
    width: 100%;
}

.admin-poginations {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
}
</style>
